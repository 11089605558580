<template>
  <div class="card">
    <div style="height: 50px; font-size: 14px">
      <el-radio style="display: contents; line-height: 50px" label="card"
        >{{langObjText.AirwallexcardPay}}
      </el-radio>

      <span
        style="float: right; color: #606266; font-size: 12px; line-height: 50px"
      >
        {{langObjText.unHandlingFees}}
      </span>
      <p v-show="gateway == 'card'">
        <img
          style="width: 200px"
          src="../../../assets/images/visa-and-mastercard-JCB-AMERICAN-EX-logo.jpg"
        />
      </p>
    </div>
    <div v-show="gateway == 'card'" class="cardBox">
      <p id="error" style="display: none" />
      <div id="card-container">
        <div class="field-container">
          <div class="field-label">{{langObjText.cardNum}}</div>
          <div id="cardNumber" />
          <p id="cardNumber-error" :style="{ color: 'red' }" />
        </div>
        <div class="field-container" style="width: 49%; float: left">
          <div class="field-label">{{langObjText.cardExpiry}}</div>
          <div id="expiry" />
          <p id="expiry-error" :style="{ color: 'red' }" />
        </div>
        <div class="field-container" style="width: 49%; float: right">
          <div class="field-label">Cvc</div>
          <div id="cvc" />
          <p id="cvc-error" :style="{ color: 'red' }" />
        </div>
      </div>
    </div>
    <div style="color: gray; margin-top: 5px; font-size: 14px;" v-show="gateway == 'card'">
      <p style="line-height: 1.5;">
        {{langObjText.cardInfo1}}
      </p>
      <p style="line-height: 1.5;">{{langObjText.cardInfo2}}</p>
    </div>
  </div>
</template>

<script type="text/javascript" src="https://pic.compgoo.com/V3/common/assets/js/address/KRW.js?v=2.0"></script>
<script>
import {
  createElement,
  confirmPaymentIntent,
  loadAirwallex,
  getElement,
} from "airwallex-payment-elements";

const init = () => {
  loadAirwallex({
    env: "prod",
    // locale: "ko",
    origin: window.location.origin,
    fonts: [
      {
        src: "https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2",
        family: "AxLLCircular",
        weight: 400,
      },
    ],
  }).then(() => {
    const cardNumEle = createElement("cardNumber");
    const cvcEle = createElement("cvc");
    const expiryEle = createElement("expiry");

    cardNumEle.mount("cardNumber");
    cvcEle.mount("cvc");
    expiryEle.mount("expiry");
  });
};

// Set up local variable to check all elements are mounted
const elementsReady = {
  cardNumber: false,
  expiry: false,
  cvc: false,
};

const onReady = (event) => {
  const { type } = event.detail;
  if (elementsReady.hasOwnProperty(type)) {
    elementsReady[type] = true; // Set element ready state
  }
};

// Set up local variable to validate element inputs
const elementsCompleted = {
  cardNumber: false,
  expiry: false,
  cvc: false,
};

// STEP #8: Add an event listener to listen to the changes in each of the input fields
const onChange = (event) => {
  const { type, complete } = event.detail;
  if (elementsCompleted.hasOwnProperty(type)) {
    elementsCompleted[type] = complete; // Set element completion state
  }
};

const onError = (event) => {
  const { error } = event.detail;
  document.getElementById("error").innerHTML = error.message; // Example: set error message
  console.log("There was an error", event.detail.error);
};

// STEP #10: Add an event listener to get input focus status
const onFocus = (event) => {
  const { type } = event.detail;
  const element = document.getElementById(`${type}-error`);
  if (element) {
    element.innerHTML = ""; // Example: clear input error message
  }
  // Customize your input focus style by listen onFocus event
};

// STEP #11: Add an event listener to show input error message when finish typing
const onBlur = (event) => {
  const { error, type } = event.detail;
  const element = document.getElementById(`${type}-error`);
  if (element && error) {
    element.innerHTML = error.message || JSON.stringify(error); // Example: set input error message
  }
};
export default {
  props: {
    payChangeOrderState: {
      type: Boolean,
    },
    lang: {
      type: String,
      default: "ja",
    },

    gateway: {
      type: String,
      default: "card",
    },
    errorLang: {
      type: Object
    },
    langObjText: {
      type: Object,
    },
  },
  mounted() {

    //初始化加载支付控件
    init();
    window.addEventListener("onReady", onReady);
    window.addEventListener("onChange", onChange);
    window.addEventListener("onError", onError);
    window.addEventListener("onFocus", onFocus);
    window.addEventListener("onBlur", onBlur);
  },


  methods: {
    validate(type) {
      let message = "";
      if (type == "card") {
        if (!elementsCompleted.cardNumber) {
          message = this.errorLang[this.lang][14];
        } else if (!elementsCompleted.expiry) {
          message = this.errorLang[this.lang][54];
        } else if (!elementsCompleted.cvc) {
          message = this.errorLang[this.lang].N7;
        }
      }
      if (message != "") {
        alert(message);
        return false;
      }
      return true;
    },
    //AirwallexCardPay支付
    airwallexCardPay(_this, payResponse) {
      //调用支付
      const cardNumElement = getElement("cardNumber");
      confirmPaymentIntent({
        element: cardNumElement,
        id: payResponse.intent_id,
        client_secret: payResponse.client_secret,
        payment_method_options: {
          card: {
            auto_capture: true,
          },
        },
      })
        .then((cardResponse) => {
          // _this.Lockr.rm("jaChecked");
          // _this.$router.push({
          //   path: _this.successUrl,
          // });
          // return
          window.location.href = _this.successUrl;
        })
        .catch((error) => {
          _this.clearOrderState();
          let message = error.message;
          if (error.original_code) {
            message = _this.errorLang[_this.lang][error.original_code]
              ? _this.errorLang[_this.lang][error.original_code]
              : _this.errorLang[_this.lang].payError;
          }
          alert(message);
          return false;
        });
    },
  },
  beforeDestroy() {
    window.removeEventListener("onReady", onReady);
    window.removeEventListener("onChange", onChange);
    window.removeEventListener("onError", onError);
    window.removeEventListener("onFocus", onFocus);
    window.removeEventListener("onBlur", onBlur);
  },
};
</script>

<style scoped>
.cardBox {
  margin-top: 55px;
  overflow: hidden;
}

#cardNumber,
#cvc,
#expiry {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 5px;
  height: 45px;
}

.field-label {
  font-size: 14px;
  line-height: 30px;
  padding-left: 8px;
}
</style>
