
let lang = {
    ZH : {
        CompanyName: "公司名",
        PaymentAmount: "支付金额",
        AddRemarks: "添加备注(最多20字)",
        gopay: "去支付",
        unHandlingFees: "（不需要手续费・含税）",
        handlingFees: "（790日元手续费）",
        delivery: "货到付款",
        AirwallexkakaoPay: "Kakao Pay",
        PingpongpingpongPay: "信用卡支付",
        PingpongppkakaoPay: "Kakaopay",
        PingpongppnaverPay: "Naverpay",
        PingpongppKoreanCardsPay: "Korean Cards",
        AirwallexcardPay: "信用卡支付",
        FISworldPay: "在线结算",
        CardOwner: "持卡人姓名",
        cardNum: "卡号",
        cardExpiry: "有效期限",
        cardInfo1: "本网站的信用卡结算已取得安全认证，可放心安全使用。",
        cardInfo2: "请使用visa、MasterCard、JCB、American Express。",
        pingpongCardName: '持卡人姓名'
    },
    jp : {
        CompanyName: "公司名",
        PaymentAmount: "お支払い金額",
        AddRemarks: "添加备注(最多20字)",
        gopay: "お支払いを確認する",
        unHandlingFees: "(手数料不要・税込)",
        handlingFees: "(790円手数料かかります)",
        delivery: "代金引換",
        AirwallexkakaoPay: "Kakao Pay",
        PingpongpingpongPay: "クレジットカード払い",
        PingpongppkakaoPay: "Kakaopay",
        PingpongppnaverPay: "Naverpay",
        PingpongppKoreanCardsPay: "Korean Cards",
        AirwallexcardPay: "クレジットカード払い",
        FISworldPay: "オンライン決済",
        CardOwner: "カード所有者名",
        cardNum: "カード番号",
        cardExpiry: "有効期間",
        worldpayPlaceholdername: "山田太郎/YAMADA TAROU",
        cardInfo1: "本サイトのクレジットカード決済は安全認定を取得しており，安心安全にご利用いただけます。",
        cardInfo2: "visa，MasterCard，JCB，American Expressを使用してください。",
        pingpongCardName: 'お名前',
        pingpongplaceholderX: "(例)山田",
        pingpongplaceholderM: "(例)太郎",
        pingpongPlaceholderD: "月 / 年",
        deOrderPaymnetS: "注文書はすでに支払い済みです",
        deOrderPaymnetU: "支払い待ち中",
        paySuccessHead: "注文確認",
        paySuccessPrompt: "ご注文は完了になります。",
        paySuccessOrdertext: "ご注文番号",
        paySuccessOrderInfo: "該当商品や配送などに何か疑問があれば弊社のヘルプデスクオンラインにてご連絡ください。あるいは",
        paySuccessOrderInfo1: "メールでお問い合わせください。",
        paySuccessOrderEmail: "（contact@sumairu-store.com）",
    },
    kr : {
        CompanyName: "公司名",
        PaymentAmount: "지불 금액",
        AddRemarks: "添加备注(最多20字)",
        gopay: "지불 버튼",
        unHandlingFees: "（대금 상환 수수료 무료・세금 포함）",
        handlingFees: "",
        delivery: "착불",
        AirwallexkakaoPay: "Kakao Pay",
        PingpongpingpongPay: "신용카드 결제",
        PingpongppkakaoPay: "Kakaopay",
        PingpongppnaverPay: "Naverpay",
        PingpongppKoreanCardsPay: "Korean Cards",
        AirwallexcardPay: "신용카드 결제",
        FISworldPay: "신용카드 결제",
        CardOwner: "이름",
        cardNum: "카드 번호",
        cardExpiry: "유효기간",
        worldpayPlaceholdername: "李民秀",
        cardInfo1: "본 사이트의 신용카드 지불은 안전인정을 받았습니다. 안심지급하세요.",
        cardInfo2: "visa、master의 신용카드나 체크카드를 사용하십시오.",
        pingpongCardName: '이름',
        pingpongPlaceholderX: "李",
        pingpongPlaceholderM: "民秀",
        pingpongPlaceholderD: "월 / 년",
        deOrderPaymnetS: "주문 결제 완료",
        deOrderPaymnetU: "결제 대기 중",
        paySuccessHead: "메모 확인",
        paySuccessPrompt: "주문서 이미 제출했습니다.",
        paySuccessOrdertext: "주문번호",
        paySuccessOrderInfo: "휴대폰의 원활한 흐름을 유지하십시오.택배기사가 연락드릴 수 있도록 하겠습니다.문제가 있으시면 서비스 메일로 보내주시기 바랍니다.",
        paySuccessOrderInfo1: "즐거운 생활 되시길 바랍니다!",
        paySuccessOrderEmail: "（contact@iiumall.com）",

    },
}

export default lang