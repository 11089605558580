<template>
  <div class="worldPay">
    <div class="worldPay_USD_radio">
      <el-radio
        style="display: contents; line-height: 50px"
        label="worldPay_USD"
        >{{ langObjText.FISworldPay
        }}<span style="font-size: 12px">{{ langObjText.unHandlingFees }}</span>
      </el-radio>
      <span class="img">
        <img
          style="height: 22px; width: 110px"
          src="../../../assets/images/worldpay1.png"
        />
      </span>
    </div>
    <!-- <div style="height: 50px; font-size: 14px">
      <el-radio
        style="display: contents; line-height: 50px"
        label="worldPay_APMUSD"
      >
        JCB、Diners
      </el-radio>
      <span class="img">
        <img
          style="height: 50px; width: 200px"
          src="../../../assets_sjp/images/worldPay_JCB.png"
        />
      </span>
    </div> -->
    <div id="card_name" v-show="gatewayMethods == 'worldPay_USD'">
      <span>{{ langObjText.CardOwner }}</span>
      <el-input
        v-model.trim="card_name"
        class="card_name"
        :placeholder="langObjText.worldpayPlaceholdername"
        @input="cardNameIpt"
        @blur="cardNameBlur"
      ></el-input>
    </div>
    <!-- 嵌入式 -->
    <section
      class="container"
      id="container"
      v-show="
        gatewayMethods == 'worldPay_USD' || gatewayMethods == 'worldPay_APMUSD'
      "
    >
      <section class="card">
        <section class="checkout" id="card-form">
          <label class="label" for="card-pan"
            >{{ langObjText.cardNum }} <span class="type"></span
          ></label>
          <section id="card-pan" class="field"></section>

          <section class="columns">
            <section class="column">
              <label class="label" for="card-expiry">{{
                langObjText.cardExpiry
              }}</label>
              <section id="card-expiry" class="field"></section>
            </section>
            <section class="column">
              <label class="label" for="card-cvv">Cvc</label>
              <section id="card-cvv" class="field"></section>
            </section>
          </section>
          <section class="buttons">
            <button class="submit" id="submit" type="button">
              Generate Session
            </button>
            <button class="clear" id="clear" type="button">Clear</button>
          </section>
        </section>
      </section>
    </section>

    <div
      style="color: gray; font-size: 14px"
      v-show="gatewayMethods == 'worldPay_USD'"
    >
      <p>
        {{ langObjText.cardInfo1 }}
      </p>
      <p>{{ langObjText.cardInfo2 }}</p>
    </div>
  </div>
</template>

<!-- <script src="https://try.access.worldpay.com/access-checkout/v1/checkout.js"></script> -->



<script>
// For production change to "https://access.worldpay.com/access-checkout/v1/checkout.js"

//Add JavaScript code in here
import Vue from "vue";
export default {
  name: "Checkout",
  props: {
    clearOrderState: {
      type: Function,
      require: true,
    },
    lang: {
      type: String,
      default: "ko",
    },
    gatewayMethods: {
      type: String,
    },
    langObjText: {
      type: Object,
    },
  },
  data() {
    return {
      sessionState: "",
      order_no: "",
      card_name: "",
      // base_path:
      market: "",
      gateway: "",
    };
  },
  mounted() {
    // 嵌入式
    let checkoutScript = document.createElement("script");
    // checkoutScript.src ="https://try.access.worldpay.com/access-checkout/v1/checkout.js"; //测试
    checkoutScript.src ="https://access.worldpay.com/access-checkout/v1/checkout.js";
    checkoutScript.onload = () => {
      this.initialiseCheckout(this);
    };
    checkoutScript.onerror = (err) => {
      console.log(
        `Something went wrong when loading the checkout script: ${err}`
      );
    };
    document.head.appendChild(checkoutScript);
  },
  methods: {
    cardNameIpt() {
      let input = document.querySelector("#card_name .el-input__inner");

      input.classList.add("color");
    },
    cardNameBlur() {
      let input = document.querySelector("#card_name .el-input__inner");
      if (this.card_name.length == 0) {
        input.classList.remove("success");
        input.classList.remove("color");
        input.classList.add("err");
        // alert("カード所有者名を空白にすることはできません。");
      } else {
        input.classList.remove("err");
        input.classList.remove("color");
        input.classList.add("success");
      }
      this.$emit("cardName", this.card_name);
    },

    // 嵌入式
    initialiseCheckout(that) {
      // const checkoutId = "8fceff0e-6013-45cd-bec4-567af18a7c4e"; //测试
      const checkoutId = "216ff996-c2c8-4da4-b819-d06d15fb5f85";
      const containerSelector = "#container";
      const panParentSelector = "#card-pan";
      const cvvParentSelector = "#card-cvv";
      const expiryDateParentSelector = "#card-expiry";
      (function () {
        var submitButton = document.querySelector("#submit");
        var clearButton = document.querySelector("#clear");
        window.Worldpay.checkout.init(
          {
            id: checkoutId,
            form: containerSelector,
            fields: {
              pan: {
                selector: panParentSelector,
                placeholder: "4444 3333 2222 1111",
              },
              expiry: {
                selector: expiryDateParentSelector,
                placeholder: "MM/YY",
              },
              cvv: {
                selector: cvvParentSelector,
                placeholder: "123",
              },
            },
            styles: {
              input: {
                color: "black",
                // "font-weight": "500",
                "font-size": "14px",
                "letter-spacing": "3px",
                "font-family": "微软雅黑",
              },
              "input#pan::placeholder": {
                color: "#ccc",
                "font-weight": "400",
              },
              "input#expiry::placeholder": {
                color: "#ccc",
                "font-weight": "400",
              },
              "input#cvv::placeholder": {
                color: "#ccc",
                "font-weight": "400",
              },

              "input.is-valid": {
                color: "green",
              },
              "input.is-invalid": {
                color: "red",
              },
              "input.is-onfocus": {
                color: "black",
              },
            },
            accessibility: {
              ariaLabel: {
                pan: "my custom aria label for pan input",
                expiry: "my custom aria label for expiry input",
                cvv: "my custom aria label for cvv input",
              },
              lang: {
                enabled: "true",
                locale: "en-GB",
              },
              title: {
                enabled: "true",
                pan: "my custom title for pan",
                expiry: "my custom title for expiry date",
                cvv: "my custom title for security code",
              },
            },

            enablePanFormatting: true,
          },

          function (error, checkout) {
            if (error) {
              console.log(`Failed to initialise the sdk: ${error}`);
              return;
            }
            submitButton.addEventListener("click", function (event) {
              event.preventDefault();
              checkout.generateSessions(function (err, sessions) {
                if (err) {
                  // handle session state generation error
                  return;
                }
                // send sessions to the server
                // console.log(
                //   "Sessions Card : " +
                //     sessions.card +
                //     ", Sessions CVV : " +
                //     sessions.cvv
                // );
                let sessionsCard = sessions.card;
                let sessionsCvc = sessions.cvv;
                // 获取sessionid
                function create_uuid() {
                  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                    /[xy]/g,
                    function (c) {
                      var r = (Math.random() * 16) | 0,
                        v = c == "x" ? r : (r & 0x3) | 0x8;
                      return v.toString(16);
                    }
                  );
                }
                var sessionid = create_uuid(); // function to create UUID
                awddc.prfl("ddc-test.worldpay.com", "afevfjm6", sessionid); // call Threatmetrix
                // console.log(sessionid);
                // this.sessionState = sessionState;
                that.$ajax
                  .post("worldpay/paymentImplant",
                    that.qs.stringify({
                      order_no: that.order_no,
                      session_href: sessionsCard,
                      card_name: that.card_name,
                      session_id: sessionid,
                      cvc_href: sessionsCvc,
                      market: that.market,
                      gateway: that.gateway.split('_')[0]
                      // return_url: this.successUrl,
                      // worldpay_type: this.worldpay_type,
                    })
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      that.$router.push({
                        path: "/paySuccess/" + res.data.data.number,
                        
                      });
                    } else if (res.data.code == 300001) {
                      that.clearOrderState();
                      alert(that.langObjText.deOrderPaymnetS);
                    } else if (res.data.code == 300002) {
                      that.clearOrderState();
                      alert(that.langObjText.deOrderPaymnetU);
                    } else {
                      alert(res.data.msg);
                      that.clearOrderState();
                    }
                  })
                  .catch(function (err) {
                    console.log(err);
                  });
              });
            });
            clearButton.addEventListener("click", function (event) {
              event.preventDefault();
              checkout.clearForm(function () {});
            });
          }
        );
      })();
    },
    worldPay(that, obj) {
      this.order_no = obj.order_no;
      this.market = obj.market;
      this.gateway = obj.gateway
      document.querySelector("#submit").click();
      // this.clearOrderState();
      // this.$nextTick(
      //   this.$ajax
      //     .post(
      //       Vue.prototype.placeOrderUrl.replace(
      //         "buy/dopay",
      //         "worldPay/payment"
      //       ),
      //       this.qs.stringify({
      //         ...obj,
      //         sessionState: this.sessionState,
      //         card_name: 123,
      //         // return_url: this.successUrl,
      //         // worldpay_type: this.worldpay_type,
      //       })
      //     )
      //     .then((res) => {
      //       console.log(res);
      //     })
      // );
    },
  },
};
</script>

<style scoped>
.worldPay_USD_radio {
  height: 50px;
  font-size: 14px;
}
.img {
  float: right;
  display: flex;
  height: 100%;
  align-items: center;
}

@media screen and (max-width: 720px) {
  .worldPay_USD_radio {
    height: 100%;
  }
  .img {
    float: none;
    display: block;
    height: 100%;
    align-items: center;
    margin: -15px 0 10px 25px;
  }
}

/*Add CSS code in here*/
body {
  font: 11px/22px sans-serif;
  background-color: #f7f7f7;
  color: black;
}
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.card {
  position: relative;
  background: white;
  padding: 10px 0px;
  /* top: -30px; */
  width: 100%;
  /* max-width: 300px; */
  border-radius: 12px;
  box-shadow: 3px 3px 60px 0px rgba(0, 0, 0, 0.1);
}
.columns {
  display: flex;
  justify-content: space-between;
}
.columns .column {
  /* margin-right: 15px; */
}
.field {
  height: 50px;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding-left: 10px;
}
.label {
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 15px;
  display: block;
}
.field.is-onfocus {
  border-color: black;
}
.field.is-empty {
  border-color: orange;
}
.field.is-invalid {
  border-color: red;
}
.field.is-valid {
  border-color: green;
}
#card-pan {
  margin-bottom: 10px;
}
.card .checkout .submit {
  background: green;
  cursor: pointer;
  width: 200px;
  margin-top: 30px;
  color: white;
  outline: 0;
  font-size: 14px;
  border: 0;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px 0;
  transition: background 0.3s ease;
  margin-right: 20px;
}
.card .checkout .clear {
  background: green;
  cursor: pointer;
  width: 100px;
  margin-top: 30px;
  color: white;
  outline: 0;
  font-size: 14px;
  border: 0;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px 0;
  transition: background 0.3s ease;
}
.buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  display: none;
}

.card .checkout.visa .label .type:before {
  content: "(visa)";
}
.card .checkout.mastercard .label .type:before {
  content: "(master card)";
}
.card .checkout.amex .label .type:before {
  content: "(american express)";
}

#card_name {
  margin-bottom: 0;
}
#card_name span {
  display: block;
  font-size: 14px;
  text-indent: 1em;
}

.card_name >>> .el-input__inner {
  font-size: 14px;
  padding: 2px 10px;
  margin-top: 5px;
  height: 50px;
  /* font-weight: 549; */
}
.card_name >>> .el-input__inner:focus {
  border-color: #ffa500;
}
.card_name >>> .el-input__inner.err {
  border-color: red;
}
.card_name >>> .el-input__inner.success {
  border-color: green;
  color: green;
}
.card_name >>> .el-input__inner.color::placeholder {
  color: #ccc;
  /* font-weight: 550; */
}
.card_name >>> .el-input__inner.color {
  color: black;
  border-color: #141417;
}
/* .card .checkout.visa .label .type:before {
  background-image: url("../../../assets_kr/images/buy2.png")
}
.card .checkout.mastercard .label .type:before {
  background-image: url("../../../assets_kr/images/buy2.png")
}
.card .checkout.amex .label .type:before {
  background-image: url("../../../assets_kr/images/buy2.png")"
} */
/* .card .checkout.visa .label .type:before {
  background-image: url("../../../assets_kr/images/buy2.png");
} */
</style>
    
