<template>
  <div id="divPay">
    <!-- <h3><span class="require">*</span>お支払い方法</h3> -->

    <!-- 支付选择 start -->
    <div id="payMethod" style="margin-bottom: 10px">
      <el-radio-group style="width: 100%" v-model="gateway">
        <div
          v-if="
            payMethodsArr.cashDelivery
              ? payMethodsArr.cashDelivery.includes('cashDelivery')
              : ''
          "
          style="height: 40px; font-size: 14px"
        >
          <CashDelivery
          :langObjText="langObj"
          ></CashDelivery>
        </div>
        <div
          v-if="
            payMethodsArr.Airwallex
              ? payMethodsArr.Airwallex.includes('kakaoPay')
              : ''
          "
          style="height: 50px; font-size: 14px"
        >
          <KakaoPay
            ref="KakaoPayRef"
            @payChangeOrderState="clearOrderState"
            :lang="lang"
            :errorLang="errorLang"
            :langObjText="langObj"
            
          ></KakaoPay>
        </div>

        <div v-if="payMethodsArr.FIS ? true : ''">
          <WorldPay
            ref="WorldPayRef"
            :clearOrderState="clearOrderState"
            :lang="lang"
            :gatewayMethods="gateway"
            @cardName="cardNameFn"
            :langObjText="langObj"
          ></WorldPay>
        </div>
        
        <div
          v-if="
            payMethodsArr.Airwallex
              ? payMethodsArr.Airwallex.includes('cardPay')
              : ''
          "
        >
          <CardPay
            ref="CardPayRef"
            :gateway="gateway"
            :errorLang="errorLang"
            :langObjText="langObj"
          ></CardPay>
        </div>
        <div
          v-if="
            payMethodsArr.Pingpong
              ? payMethodsArr.Pingpong.includes('pingpongPay')
              : ''
          "
        >
          <Pingpongcheckout
            :pingpongArr="payMethodsArr.Pingpong"
            :pingpongState="pingpongState"
            :gateway="gateway"
            ref="pingpongPayRef"
            @pingpongForm="pingpongInfoPay"
            :langObjText="langObj"
          ></Pingpongcheckout>
        </div>
      </el-radio-group>
    </div>
    
  </div>
</template>

<script>
import CardPay from "./Airwallex/CardPay.vue";
import KakaoPay from "./Airwallex/KakaoPay.vue";
import WorldPay from "./FIS/WorldPayQR.vue";
import CashDelivery from "./CashDelivery/CashDelivery.vue";
import Pingpongcheckout from "./pingpongcheck/pingpongcheckout.vue";

export default {
  props: {
    name: {
      type: String,
      default: "货到付款",
    },
    market: {
      type: String,
    },
    langObj: {
      type: Object,
    },
    // lang: {
    //   type: String,
    //   default: "ja",
    // },
    methodsArr: {
      type: Object,
      default: {
        // cashDelivery: ["cashDelivery"],
        // FIS:["worldPay_USD"],
        // Airwallex: ['card', "kakaoPay"],
        // Pingpong: ['pingpongPay']
      },
    },
    payChangeOrderState: {
      type: Boolean,
    },
    orderNumber: {
      type: String
    }
  },
  data() {
    return {
      gateway: "cashDelivery",
      paymentMethodNum: 0,
      successUrl: "",
      cancelUrl: "",
      payMethodsArr: this.MethodsArr,
      errorLang: {
        ja: {
          //日本
          httpError: "処理エラー、再試行してください",
          14: "カード番号が存在しません",
          15: "カード番号はカード発行銀行がサポートする番号セグメント内にありません",
          51: "残高不足",
          54: "カード有効期限エラー",
          N7: "CVV無効",
          payError:
            "カード発行銀行は取引を拒否します。カードを交換して再試行してください",
        },
        tc: {
          //中文繁体
          httpError: "處理錯誤，請重試",
          14: "卡號不存在",
          15: "卡號不在發卡銀行支持的編號段內",
          51: "餘額不足",
          54: "卡過期錯誤",
          N7: "CVV無效",
          payError: "發卡銀行拒絕交易。 請更換卡並重試",
        },
        ko: {
          //韩文
          httpError: "오류 처리, 다시 시도하십시오.",
          14: "카드는 존재하지 않습니다",
          15: "카드 번호는 카드 은행에서 지원하는 번호 섹션에 없습니다",
          51: "잔액 부족",
          54: "카드 만료 오류",
          N7: "CVV 유효하지 않음",
          payError:
            "카드 발급 은행이 거래를 거부합니다.카드를 교체하고 다시 시도하십시오.",
        },
        en: {
          //英文
          httpError: "Processing error, please try again",
          14: "Card number does not exist",
          15: "The card number is not within the number range supported by the issuing bank",
          51: "Insufficient balance",
          54: "Card expiration error",
          N7: "CVV invalid",
          PayError:
            "The issuing bank refused the transaction. Please replace the card and try again ",
        },
      },
      payInfo: {
        worldPay_APMUSD: {
          company: "Worldpay",
          method: "worldPay",
          ref: "WorldPayRef",
        },
        worldPay_USD: {
          company: "Worldpay",
          method: "worldPay",
          ref: "WorldPayRef",
        },
        card: {
          company: "Airwallex",
          method: "airwallexCardPay",
          ref: "CardPayRef",
        },
        kakaoPay: {
          company: "Airwallex",
          method: "airwallexKakaoPay",
          ref: "KakaoPayRef",
        },
        pingpongPay: {
          company: "Pingpong",
          method: "pingpongPay",
          ref: "pingpongPayRef",
        },
        ppThreePayment: {
          company: "Pingpong",
          method: "ppThreePayment",
          ref: "ppThreePaymentRef",
        },
      },
      marketType: {
        ko: ["jykkrusd", "jykkrapmusd"],
        ja: ["jykjpusd", "jykjpapmusd"],
      },
      orderNo: "",
      // worldPay持卡人姓名
      worldPayCardNamePay: '',
      // pingpong支付
      pingpongInfo: "",
      pingpongState: true,

    };
  },
  components: {
    CardPay,
    KakaoPay,
    WorldPay,
    CashDelivery,
    Pingpongcheckout,
  },
  computed: {
    lang() {
      if(this.market == "kr") {
        return "ko"
      }else if(this.market == "jp"|| this.market == "ea") {
        return "ja"
      }else {
       return "en"
      }
    }
  },
  watch: {
    gateway(val) {
      if (val === "cashDelivery") {
        this.paymentMethodNum = 0;
      } else {
        this.paymentMethodNum = 1;
      }
      this.$emit("paySonMethod", {
        gateway: val,
        paymentMethodNum: this.paymentMethodNum,
      });
      // this.worldpay_type =
      //   val == "worldPay_USD"
      //     ? this.marketType[this.lang][0]
      //     : val == "worldPay_APMUSD"
      //     ? this.marketType[this.lang][1]
      //     : "";
    },
    methodsArr: {
      immediate: true,
      handler(val) {
        if (val == null) {
          this.payMethodsArr = {
            cashDelivery: ["cashDelivery"],
          };
        } else {
          this.payMethodsArr = val;
        }
        if (this.methodsArr["FIS"]) {
          this.gateway = "worldPay_USD";
        } else if (
          this.methodsArr["Airwallex"] &&
          this.methodsArr["Airwallex"].includes("cardPay")
        ) {
          this.gateway = "card";
        } else if (
          this.methodsArr["Airwallex"] &&
          this.methodsArr["Airwallex"].includes("kakaoPay")
        ) {
          this.gateway = "kakaoPay";
        } else if (this.methodsArr["Pingpong"]) {
          this.gateway = "pingpongPay";
        } else {
          this.gateway = "cashDelivery";
        }
      },
    },
    pingpongInfo: {
      deep: true,
      handler(val) {
        this.$emit("pingpongInfo", val);
      },
    },
    worldPayCardNamePay: {
      deep: true,
        handler(val) {
          this.$emit('worldPayCardName', val)
        }
      }
  },
  methods: {
    /**
     * 支付
     * @param res
     * @param options {successUrl:'xxxx.com',cancelURL:'xxxx.com'...} //可根据需要自己随意补充
     */
    payMethodChild(res, options) {
      //单号
      this.orderNo = res.orderNum;
      // this.market = res.market
      
      //成功跳转地址 例:https://www.abc.com/paySuccess/8200001
      if (!options || !options.successUrl) {
        this.clearOrderState();
        console.log("必须传递成功回调地址");
        alert(this.errorLang[this.lang].httpError);
        return false;
      }

      this.successUrl = options.successUrl;

      //失败跳转地址
      this.cancelURL = options.cancelURL ? options.cancelURL : location.href;

      
      if(this.gateway == "worldPay_USD" ) {
        this.$refs.WorldPayRef.worldPay(this ,{order_no: this.orderNo,gateway: this.gateway})
        return
      }

      // if (!this.repeatOrderFlag) {
      // }
      
      this.dopay();
    },

    //错误时候解除下单按钮禁止点击效果
    clearOrderState(bool) {
      this.$store.state.loadingState = false;
      this.$emit("update:payChangeOrderState", true);
      if (bool) {
        this.$emit("update:payChangeOrderState", bool);
      }
    },

    pingpongInfoPay(form) {
      this.pingpongInfo = form;
    },
    dopay() {
      let pingpongInfo = {};
      if (this.gateway == "pingpongPay") {
        let arr = this.pingpongInfo.failureTime.split("/");
        pingpongInfo = JSON.parse(JSON.stringify(this.pingpongInfo));
        delete pingpongInfo.failureTime;
        pingpongInfo.expire_month = arr[0];
        pingpongInfo.expire_year ="20" + arr[1];
        pingpongInfo.gateway = this.gateway
      }
      if (
        this.gateway == "ppkakaoPay" ||
        this.gateway == "ppnaverPay" ||
        this.gateway == "ppKoreanCardsPay"
      ) {
        this.$refs.pingpongPayRef.threePayment({
          order_no: this.orderNo,
          return_url: this.successUrl,
          gateway: this.gateway
          // market: this.market
        });
        return;
      }
       //调用各公司的初始化支付
       this.$ajax
        .post(`${this.payInfo[this.gateway].company}/payment`,
          this.qs.stringify({
            order_no: this.orderNo,
            return_url: this.successUrl,
            // worldpay_type: this.worldpay_type,
            ...pingpongInfo,
            gateway: this.gateway
            // market: this.market
          })
        )
        .then((payResponse) => {
          if (payResponse.data.code == 0) {
            //成功
            let methodList = this.$options.methods;
            let methodRef = this.$refs[this.payInfo[this.gateway].ref];
            let _this = this;

            methodRef[this.payInfo[this.gateway].method](
              _this,
              {...payResponse.data.data,
                orderNumber: this.orderNumber,
                gateway: this.gateway
              }
            );
          }else if(payResponse.data.code == 300001){
            this.clearOrderState();   
            alert(this.langObj.deOrderPaymnetS)
          }else if(payResponse.data.code == 300002) {
            this.clearOrderState();   
            alert(this.langObj.deOrderPaymnetU)
          } else {
            this.clearOrderState();
            if (this.gateway == "pingpongPay") {
              this.pingpongState = false;
              // this.$refs.pingpongPayRef.bankForm = {}
            }
            alert(this.errorLang[this.lang].httpError);
            return false;
          }
        })
        .catch((error) => {
          this.clearOrderState();          
          alert(this.errorLang[this.lang].httpError);
          console.log(error);
          return false;
        });
    },
     // worldPay持卡人姓名信息
     cardNameFn(name) {
      this.worldPayCardNamePay = name
    },
  },
};
</script>

<style>
#divPay {
  /* width: 97%; */
  /* margin-left: -3%; */
  padding: 10px 10px 0;
  border: 1px solid #ddd;
  border-radius: 15px;
  background-color: #fff;
}
@media screen and (min-width:450px) {
  #divPay {
  /* width: 95%; */
  /* float: right; */
  /* margin-right: 25px; */
  margin: 0 auto;
  background-color: #fff;
}
  }
</style>