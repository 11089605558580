<template>
  <div class="paySuccess">
    <div class="header">
        <div class="header_main">
        <!-- <img
          src="../assets/images/icon-back.png"
          alt=""
          class="goBack"
          @click="goBack()"
        /> -->
        <div class="header_text">{{langObj.paySuccessHead}}</div>
      </div>
    </div>
    <div class="pay_content">
      <div class="pay_image">
        <img src="../assets/images/pay_image.png" style="width: 100%" />
      </div>
      <div class="pay_success" style="margin-bottom: 20px">
        <h2 style="padding: 40px 15px 0px; color: #b4272d">
          {{langObj.paySuccessPrompt}}
        </h2>
        <div style="padding: 15px">
          {{ langObj.paySuccessOrdertext }} :
          <font color="#B4272D">{{ orderNumber }}</font>
          <br />
        </div>
        <div style="text-align: left; padding: 10px 15px 20px">
          {{langObj.paySuccessOrderInfo}}<a
            href="mailto:contact@sumairu-store.com"
            style="color: #4cb3e8"
            >{{langObj.paySuccessOrderEmail}}</a
          >{{ langObj.paySuccessOrderInfo1 }}
        </div>
        <div align="left" style="padding: 0px 15px">
          <router-link to="/">
            <!-- <button type="button" class="succuss_center_a">戻る</button> -->
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Lang from '../common/lang.js'
export default {
  data() {
    return {
      orderNumber: this.$route.params.number,
      langObj: {}
    };
  },
  created() {
    this.$store.state.loadingState = false
    this.$ajax.post('paymethod/updatePaymentStatus',
    this.qs.stringify({
        order_no: this.orderNumber,
        
      })).then(res => {
        this.langObj = Lang[res.data.data.market]

        if(res.data.code == 0) {

        }
      })
      .catch(err => {

      })
  },
  methods: {
    goBack() {
        this.$router.push({
        path: "/",
      });
    }
  }
};
</script>

<style>
.pay_content {
  width: 100%;
  max-width: 750px;
  height: auto;
  background: #fefefe;
  margin: 30px auto 20px;
  padding-top: 50px;
}

.pay_content::after {
  content: "";
  clear: both;
  display: block;
}

.pay_image {
  width: 20%;
  height: auto;
  float: left;
  padding-top: 6%;
  margin-left: 3%;
}

.pay_success {
  width: 75%;
  float: right;
  height: auto;
}

.succuss_center_a {
  display: inline-block;
  width: 140px;
  height: 40px;
  text-align: center;
  color: #fff;
  border: 0;
  margin-right: 10px;
  text-decoration: none;
  background: #b4272d;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.succuss_center_b {
  display: inline-block;
  width: 140px;
  height: 40px;
  text-align: center;
  color: #eff804;
  border: 0;
  text-decoration: none;
  background: #b4272d;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}


.header {
    height: 50px;
}

.header_main {
  margin: 0 auto;
  min-width: 320px;
  width: 100%;
  max-width: 750px;
  align-items: center;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(237, 237, 237, 1);
  color: #333;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  
}
.header_main .goBack {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  z-index: 1000;
}
.header_main .header_text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  /* margin-left: -40px; */
}
</style>