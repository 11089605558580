<template>
  <div class="home">
    <el-container>
      <el-header>Payment</el-header>
      <el-main>
        <div class="head"></div>
        <div class="body">
          <div class="payInfo">
            <p class="PayPrice">
              <span>{{langObj.PaymentAmount}}：</span>
              <span><b>{{price}}</b> {{currency}}</span>
            </p>
            <!-- <p class="PayNotes">
              <span @click="PayNotesState = true">{{langObj.AddRemarks}}</span>
              <el-input
                v-if="PayNotesState"
                v-model.trim="notesText"
                @blur="
                  notesText.length == 0
                    ? (PayNotesState = false)
                    : (PayNotesState = true)
                "
              ></el-input>
            </p> -->
          </div>
          <div class="payMethods">
            <PayIndex
              ref="paymentChild"
              @paySonMethod="paySonChange"
              :market="market"
              :methodsArr="paymentMethodsArr"
              :payChangeOrderState.sync="orderState"
              @pingpongInfo="pingpongInfoPay"
              @worldPayCardName="worldPayCardNameStaFn"
              :langObj="langObj"
              :orderNumber="orderNum"
            ></PayIndex>
           
          </div>
        </div>
        <div class="foot">
          <el-button @click="dopay" v-loading="$store.state.loadingState">{{langObj.gopay}}</el-button>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import PayIndex from "../components/pay/index.vue";
import Lang from '../common/lang.js'
export default {
  name: "HomeView",
  components: {
    PayIndex,
  },
  data() {
    return {
      // loading:false,
      PayNotesState: false,
      radio: "",
      notesText: "",
      lang: "ko",
      paymentMethodsArr: {
        // cashDelivery: ["cashDelivery"],
        // FIS: ["worldPay_USD"],
        // Airwallex: ["card", "kakaoPay"],
        // Pingpong: ["pingpongPay"],
      },
      orderState: true,
      orderNum: "8234832462ZF",
      paymentMethod: "",
      paymentMethodNum: "",
      pingpongInfoForm: "",
      worldPayCardNameSta: "",
      market: '',
      price: '',
      currency: "",
      langObj: {
        CompanyName: "",
        PaymentAmount: "",
        AddRemarks:"",
        gopay: "",
        unHandlingFees: "",
        handlingFees: "",
        delivery: "",
        AirwallexkakaoPay: "",
        PingpongpingpongPay: "",
        PingpongppkakaoPay: "",
        PingpongppnaverPay: "",
        PingpongppKoreanCardsPay: "",
        AirwallexcardPay: "",
        FISworldPay: "",
        CardOwner: "",
        cardNum: "",
        cardExpiry: "",
        cardInfo1: "",
        cardInfo2: "",
        pingpongCardName: ""
      }
    };
  },
  created() {
    let urlAll = window.location.href;
    let index = urlAll.indexOf("orderNum");
    this.orderNum = urlAll.substring(index).split("&")[0].split("=")[1]
    this.getPaymentDetail(this.orderNum)
  },
  mounted() {
  },
  methods: {

    // 获取支付方式
    getPaymentDetail(num) {
      this.$ajax.post('paymethod/detail', 
      this.qs.stringify({
        order_no: num
      })).then(res => {
        if(res.data.code == 0) {
          this.market = res.data.data.market
          // this.market = "SJP"
          this.price = res.data.data.real_sum
          this.currency = res.data.data.currency_symbol

          this.langObj = Lang[this.market]
          let paymentArr = JSON.parse(res.data.data.pay_method)
          let obj = {}
          paymentArr.forEach(item => {
           let  arr = item.split('-')
           if(obj[arr[0]]) {
            obj[arr[0]].push(arr[1])
          }else {
             obj[arr[0]]=[arr[1]]
           }
          })          
          this.paymentMethodsArr = obj
        }
      })
    },
    paySonChange(val) {
      this.paymentMethod = val.gateway;
      this.paymentMethodNum = val.paymentMethodNum;
    },
    pingpongInfoPay(form) {
      this.pingpongInfoForm = form;
    },

    // worldPay 持卡人姓名信息
    worldPayCardNameStaFn(name) {
      this.worldPayCardNameSta = name;
    },

    dopay() {
      
      // pingpong支付校验
      if (this.paymentMethod == "pingpongPay") {
        if (
          this.pingpongInfoForm.card_number == "" ||
          this.pingpongInfoForm.failureTime == "" ||
          this.pingpongInfoForm.first_name == "" ||
          this.pingpongInfoForm.cvv == "" ||
          this.pingpongInfoForm.last_name == ""
        ) {
          alert("支払情報を空にすることはできません");
          return;
        } else if (this.pingpongInfoForm.failureTime.length != 5) {
          alert("2桁の月と2桁の年でなければなりません");
          return;
        }
      }

      if (
        this.paymentMethod == "worldPay_USD" ||
        this.paymentMethod == "worldPay_APMUSD"
      ) {
        // console.log(document.querySelector('#card-pan'));
        // console.log(this.worldPayCardNameSta);
        if (this.worldPayCardNameSta.length == 0) {
          alert("カード所有者名を空白にすることはできません。");
          return;
        }
      }

      //在线支付做效验
      if (this.paymentMethod == "card") {
        if (
          !this.$refs.paymentChild.$refs.CardPayRef.validate(this.paymentMethod)
        ) {
          return false;
        }
      }
      this.$store.state.loadingState = true
      if (this.paymentMethodNum == 1) {
        this.$refs.paymentChild.payMethodChild(
          {
            gateway: this.paymentMethod,
            orderNum: this.orderNum,
          },
          {
            successUrl: window.location.origin + "/paySuccess/" + this.orderNum, //成功跳转地址
          }
        );
      } else {
        this.$router.push({
          path: "/paySuccess/" + this.orderNum,
          
        });
      }
    },
  },
};
</script>

<style>
.el-container {
  max-width: 750px;
  margin: 0 auto;
  background-color: #f6f6f6;
}

.el-container .el-header {
  background-color: #555;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
}
.el-container .el-main {
  padding: 20px 0;
}
.el-main .head {
  text-align: center;
  font-size: 20px;
  height: 30px;
  margin-bottom: 20px;
}
.el-main .payInfo {
  border: 1px solid #333;
  border-radius: 10px;
  padding: 2px;
  margin: 0 10px;
}

.el-main .payInfo .PayPrice {
  padding: 15px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 5vw;
  /* border-bottom: 1px solid #ddd; */
}
.el-main .payInfo .PayPrice b {
  font-weight: 400;
  font-size: 6vw;
  color: #aaa;
}

@media screen and (min-width: 500px) {
  .el-main .payInfo .PayPrice {
  
  font-size: 2vw;
}
.el-main .payInfo .PayPrice b {
  font-size: 3vw;
}

}


.el-main .payInfo .PayNotes {
  padding: 10px;
  margin: 0;
}

.el-main .payInfo .el-input {
  margin-top: 10px;
}

.payMethods {
  margin: 20px 10px;
}
.payMethods .el-radio-group {
  width: 100%;
}
.payMethods .payMethodsItem .el-col .el-radio {
  float: right;
}
.payMethods .payMethodsItem p {
  float: left;
  margin: 0;
  font-size: 16px;
}


.foot {
  padding: 0 10px;
}
.foot .el-button {
  margin-top: 30px ;
  width: 100%;
  background-color: #ff797e;
  font-size: 16px;
  color: #fff;
}

body {
  margin: 0;
}
</style>
