<template>
  <div class="main">
    <div
      class="threePayment"
      v-for="(item, index) in methodsArr"
      :key="index"
      style="height: 50px; font-size: 14px"
    >
      <el-radio style="display: contents; line-height: 50px" :label="item">
        <!-- <span style="margin-top: 5px"
          ><img
            :src="require('../../../assets/images/pp_' + pp_paymentArr[item] + '.png')"
            :alt="pp_paymentArr[item]"
        />
      </span> -->
      <span>{{ pp_paymentArr[item] }}</span>
        <span style="color: #606266; font-size: 12px; line-height: 50px">
          {{langObjText.unHandlingFees}}

        </span>
      </el-radio>
    </div>
    <div class="cashDelivery">
      <el-radio
        style="display: contents; line-height: 50px"
        label="pingpongPay"
      >
        {{langObjText.PingpongpingpongPay}}<span style="font-size: 12px;">{{langObjText.unHandlingFees }}</span>
        <!-- <span style="color: #606266; font-size: 12px; line-height: 50px">
          (手数料不要・税込)
        </span> -->
      </el-radio>
    </div>
    <el-form ref="form" :model="bankForm" v-show="gateway == 'pingpongPay'">
      <el-form-item :label="langObjText.pingpongCardName" id="labelname1">
        <el-col :span="11">
          <el-input
            :placeholder="langObjText.pingpongPlaceholderX"
            type="text"
            id="FirstName"
            v-model="bankForm.first_name"
            style="width: 100%"
            @focus="focusInput(1)"
            @blur="blurInput(1)"
          ></el-input>
        </el-col>
        <el-col :span="2" style="text-align: center">-</el-col>
        <el-col :span="11">
          <el-input
            :placeholder="langObjText.pingpongPlaceholderM"
            id="LastName"
            v-model="bankForm.last_name"
            style="width: 100%"
            @focus="focusInput(2)"
            @blur="blurInput(2)"
          ></el-input
        ></el-col>
      </el-form-item>

      <el-form-item :label="langObjText.cardNum" id="labelname3">
        <el-input
          v-model="bankForm.card_number"
          :placeholder="langObjText.cardNum"
          @focus="focusInput(3)"
          @blur="blurInput(3)"
          id="cardName"
          maxlength="25"
        ></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="11" class="failureTime">
          <el-form-item :label="langObjText.cardExpiry" id="labelname4">
            <el-input
              type="expiryDate"
              :placeholder="langObjText.pingpongPlaceholderD"
              maxlength="7"
              minlength="7"
              id="failureTime"
              v-model.trim="bankForm.failureTime"
              style="width: 100%"
              @focus="focusInput(4)"
              @blur="blurInput(4)"
              @input="changeTime($event)"
            ></el-input></el-form-item
        ></el-col>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="11">
          <el-form-item label="Cvc" prop="CVV" id="labelname5"
            ><el-input
              id="CVV"
              placeholder="XXX"
              v-model="bankForm.cvv"
              style="width: 100%"
              maxlength="3"
              @focus="focusInput(5)"
              @blur="blurInput(5)"
            ></el-input></el-form-item
        ></el-col>
      </el-row>
    </el-form>
    <div style="color: gray; margin-top: 5px; font-size: 14px;" v-show="gateway == 'pingpongPay'">
      <p>
        {{langObjText.cardInfo1}}
      </p>
      <p> {{langObjText.cardInfo2}}</p>
    </div>
  </div>
</template>



<script>
import Vue from "vue";
export default {
  props: {
    gateway: {
      type: String,
      default: "",
    },
    pingpongState: {
      type: Boolean,
      default: true,
    },
    pingpongArr: {
      type: Array,
    },
    langObjText: {
      type: Object,
    },
  },
  data() {
    return {
      // gateway: this.gateway
      path: 'Kakaopay',
      dialogVisible: false,
      bankForm: {
        first_name: "",
        last_name: "",
        card_number: "",
        failureTime: "",
        cvv: "",
      },
      pp_paymentArr: {
        ppkakaoPay: "Kakaopay",
        ppnaverPay: "Naverpay",
        ppKoreanCardsPay: "Korean Cards",
      },
      methodsArr: [],
    };
  },
  created() {
    this.methodsArr = this.pingpongArr.filter(
      (item) => this.pp_paymentArr[item]
    );
    
  },
  methods: {
    focusInput(num) {
      // document
      //   .getElementById(`labelname${num}`)
      //   .children[0].classList.add("inputClick");
    },
    blurInput(num) {
      // if (
      //   document.getElementById(`labelname${num}`).children[1].children[0]
      //     .children[0].value.length == 0
      // ) {
      //   document
      //     .getElementById(`labelname${num}`)
      //     .children[0].classList.remove("inputClick");
      // }
      this.$emit("pingpongForm", this.bankForm);
    },
    changeTime(value) {
      let ipt = document.querySelector("#failureTime");

      if (ipt.selectionStart > 2 && ipt.selectionStart < 5) {
        let arr = this.bankForm.failureTime.split("");
        if (!arr.includes("/")) {
          arr.splice(2, 0, "/");
        }
        this.bankForm.failureTime = arr.join("");
      } else if (ipt.selectionEnd >= 5) {
        ipt.setSelectionRange(3, 5);
      }
    },
    threePayment(obj) {
      this.$ajax
        .post(          
          "pingpong/apm",
          this.qs.stringify({
            ...obj,
            payment_brand: this.pp_paymentArr[this.gateway],
            
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            window.location.href = res.data.data.pay_url;
          }else if(res.data.code == 300001){
            this.$store.state.loadingState = false
            alert(this.langObjText.deOrderPaymnetS)
          }else if(res.data.code == 300002) {
            this.$store.state.loadingState = false
            alert(this.langObjText.deOrderPaymnetU)
          }
        });
    },
    pingpongPay(that, res) {
      this.$router.push({
        path: "/paySuccess/" + res.number,
        
      });
    },
    
  },
  mounted() {},
  watch: {
    gateway: {
      immediate: true,
      handler(val) {
        this.gateway = val;
      },
    },
    pingpongState: {
      handler(bool) {
        if (bool == false) {
          this.bankForm.first_name = "";
          this.bankForm.last_name = "";
          this.bankForm.card_number = "";
          this.bankForm.failureTime = "";
          this.bankForm.cvv = "";
        }
      },
    },
  },
};
</script>
    
<style  scoped>
.main {
  /* border: 1px solid #ccc; */
  /* padding: 5px; */
  /* margin-top: -15px; */
}
.failureTime {
  margin-right: 2.5vw;
}
.el-form-item {
  margin-bottom: 5px;
}

>>> .el-input__inner {
  height: 50px;
  line-height: 50px;
}

>>> .el-form-item__label {
  text-align: left;
  margin-left: 10px;
  width: 100%;
}
/* .el-radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.el-radio span img {
  max-height: 40px;
}
.el-radio >>> .el-radio__input {
  margin-top: 18px;
}
.el-radio >>> .el-radio__label {
  float: right;
  display: flex;
  justify-content: space-between;
  width: 96%;
}
@media screen and (max-width: 750px) {
  .el-radio >>> .el-radio__label {
  
  width: 92%;
}
}
/* .el-form >>> .el-form-item {
  position: relative;
  top: 0;
  height: 50px;
}
.el-form >>> .el-form-item__label {
  position: absolute;
  left: 10px;
  line-height: 50px;
  top: 0;
  z-index: 10;
  pointer-events: none;
}
.el-form >>> .el-input {
  height: 60px;
}
.el-form >>> .el-input__inner {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  padding-top: 10px;
}
.el-form >>> .el-form-item__label.inputClick {
  top: -15px !important;
} */
</style>