import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import qs from 'qs'


Vue.config.productionTip = false
Vue.prototype.$ajax = axios;
Vue.prototype.qs = qs;

Vue.use(ElementUI);
// 测试地址
// axios.defaults.baseURL = 'http://api.new-payment.com/trading/'


// 线上地址
axios.defaults.baseURL = 'https://payment.moreonce.com/trading/'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
