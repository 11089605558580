<template>
  <div class="kakaoPay">
    <el-radio style="display: contents; line-height: 50px" label="kakaoPay"
      >{{langObjText.AirwallexkakaoPay}}
    </el-radio>
    <span style="float: right; margin-top: 7px">
      <img
        style="height: 25px; width: 68px"
        src="../../../assets/images/kakao.jpeg"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    payChangeOrderState: {
      type: Boolean,
    },
    lang: {
      type: String,
      default: "ko",
    },
    errorLang: {
      type: Object
    },
    langObjText: {
      type: Object,
    },
  },
  methods: {
    //AirwallexKakaoPay支付
    airwallexKakaoPay(_this, payResponse) {
      _this.$ajax
        .post(
          `${_this.payInfo[_this.gateway].company}/kakao`
          ,
          _this.qs.stringify({
            request_id: payResponse.request_id,
            intent_id: payResponse.intent_id,
            order_no: payResponse.orderNumber
          })
        )
        .then((kakaoResponse) => {
          if (kakaoResponse.data.code != 0) {
            _this.clearOrderState();
            alert(_this.errorLang[_this.lang].httpError);
            return false;
          }
          window.location.href = kakaoResponse.data.data.pay_url;
        })
        .catch((error) => {
          alert(this.errorLang[this.lang].httpError);
          console.log(error);
          this.$emit("payChangeOrderState", true);
          return false;
        });
    },
  },
};
</script>

<style>
</style>