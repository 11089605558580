<template>
  <div class="cashDelivery">
    <el-radio
            style="display: contents; line-height: 40px"
            label="cashDelivery"
            >{{langObjText.delivery}} {{ langObjText.handlingFees }}
          </el-radio>
  </div>
</template>

<script>
export default {
  props: {
    langObjText: {
      type: Object,
    },
  }
}
</script>

<style>

</style>